import {createSlice , createAsyncThunk}  from "@reduxjs/toolkit"
import * as api from "../../api/config";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

export const adminlogin = createAsyncThunk(
    'admin/login',
    async ({ data, navigate }, { dispatch }) => {
      try {
        const res = await api.AdminLogin(data);
        console.log(res);
        toast.success('Login successful');
  
        // Set a cookie to indicate that the user is authenticated
        Cookies.set('authCookie', 'true', { expires: 7 }); // Expires in 7 days
  
        navigate('/admin/');
        return res;
      } catch (error) {
        toast.error('Invalid Credentials');
        console.log(error);
      }
    }
  );
  export const registerAdmin = createAsyncThunk(
    'admin/register',
    async ({data,navigate}) =>{
      try {
        const res = await api.Adminregister(data);
        console.log(res);
        toast.success('Register successfull')
        navigate('/admin/')
        return res;
      } catch (error) {
        toast.error('Invalid Credentials')
        console.log(error);
      }
    }
  )
  export const adminlogout = createAsyncThunk(
    'admin/login',
    async ({ navigate }, { dispatch }) => {
      try {
        toast.success('Logout successful');
        // Set a cookie to indicate that the user is authenticated
        Cookies.set('authCookie', '', { expires: 700 }); // Expires in 7 days
  
        navigate('/admin/');

      } catch (error) {

        toast.error('Unable to Logout');

        console.log(error);
      }
    }
  );
