import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {baseURL} from "./data";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AddIcon from "@mui/icons-material/Add";
import { toast, Toaster } from "react-hot-toast";

const Contacts = () => {
  const navigate = useNavigate();

  const handleViewClick = (id) => {
    // Handle viewing the item, e.g., navigating to the item's details page.
    // You can construct the URL with the base URL and the item's ID.
    const viewUrl = `${baseURL}/${id}`;
    console.log(id); // Replace baseUrl with your actual URL
    navigate(`/property/${id}`);
  };
  const handleDeleteClick = async (itemId) => {
    const res = await fetch(`${baseURL}properties/${itemId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jack = await res.json();
    if(res.status == 200){
    toast.success("Property Deleted successfully!");
    console.log("Delete");
  }
  };
  const handleEditClick = (id) => {
    // Handle editing the item, e.g., navigating to the edit page.
    // You can construct the URL with the base URL and the item's ID.
    navigate(`/admin/property/edit/${id}`);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 33 },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "location",
      headerName: "Location",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1,
      width: "10%",
    },
    {
      field: "category",
      headerName: "Category",
      flex: 2,
    },
    {
      field: "options",
      headerName: "Options",
      flex: 1.5,
      renderCell: (params) => (
        <div>
          <IconButton
            aria-label="View"
            onClick={() => handleViewClick(params.row._id)}
            color="primary"
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            aria-label="Edit"
            onClick={() => handleEditClick(params.row._id)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="Delete"
            onClick={() => handleDeleteClick(params.row._id)}
            color="secondary"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const theme = createTheme({
    mode: "dark", // Set to "dark" for dark mode, if needed
    // Other theme properties...
  });
  const [properties, setProperties] = useState([]);
  const SetAllProperties = async () => {
    const j = `${baseURL}properties`
    const res = await fetch(
      `${baseURL}properties`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(j, "ddd");
    const jack = await res.json();
    var hmm = 0;
    setProperties(
      jack.properties.map((property) => {
        hmm += 1;
        return { ...property, id: hmm };
      })
    );
    console.log(jack, jack.properties, properties, "USERO");
  };
  useEffect(() => {
    SetAllProperties();
  }, ["o"]);
  return (
    <>
      <AdminHeader />
      <Toaster toastOptions={{ duration: 4000 }} />
      <Box>
        <div
          className="text-left"
          style={{
            fontSize: 30,
            margin: "10%",
            width: "80%",
            marginTop: 30,
            marginBottom: 10,
            fontWeight: 500,
            backgroundColor: "#f2f2f2",
            borderRadius: 5,
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Properties</div>
          <div
            className="hover:bg-black hover:text-white"
            style={{
              fontSize: "17px",
              border: "3px solid black",
              borderRadius: "8px",
              padding: 6,
              fontWeight: 700,
            }}
          >
            <Link to="/admin/form">
              <AddIcon /> Add Properties
            </Link>
          </div>
        </div>
        <Box sx={{ height: "800px", width: "80%", mx: "auto" }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              slots={{
                toolbar: GridToolbar,
              }}
              rows={properties}
              // @ts-ignore
              columns={columns}
            />
          </ThemeProvider>
        </Box>
      </Box>
    </>
  );
};

export default Contacts;
