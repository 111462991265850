import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { columns, rows, columnsReview } from "./data";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AdminHeader from "./AdminHeader";
import {baseURL} from "./data";
const Contacts = () => {
  const theme = createTheme({
    mode: "dark", // Set to "dark" for dark mode, if needed
    // Other theme properties...
  });
  const columnsComment = [
    { field: "id", headerName: "ID", width: 33 },
    {
      field: "userName",
      headerName: "User",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "content",
      headerName: "Content",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 3,
      width: "10%",
    },
    {
      field: "allowed",
      headerName: "Allowed",
      flex: 3,
      renderCell: (params) => (
        <button
          onClick={() => handleButtonClick(params.row)}
          style={{
            background: params.row.allowed ? "#7d9c53" : "red",
            padding: ".3rem",
            borderRadius: ".2rem",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          {params.row.allowed ? "Allowed" : "Not Allowed"}
        </button>
      ),
    },
  ];
  const handleButtonClick = (row) => {
    // Toggle the "allowed" field between true and false
    row.allowed = !row.allowed;

    // Make an API request to update the "allowed" field in the database
    fetch(`${baseURL}admin/toggleComment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: row._id }),
    })
      .then((response) => response.json())
      .then((updatedRow) => {
        // Handle the response if needed
        console.log("Updated Row:", updatedRow);
      })
      .catch((error) => {
        // Handle the error if the API request fails
        console.error("API Error:", error);
      });
  };
  const [comments, setComments] = useState([]);
  const SetAllComments = async () => {
    const res = await fetch(
      `${baseURL}admin/getAllCommentsOld`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const jack = await res.json();
    var hmm = 0;
    setComments(
      jack.comments.map((review) => {
        hmm += 1;
        return { ...review, id: hmm };
      })
    );
    console.log(jack, comments, "BlaBlaBla");
  };
  useEffect(() => {
    SetAllComments();
  }, [0]);
  return (
    <Box>
      <AdminHeader />
      <div
        className="text-left"
        style={{
          fontSize: 30,
          width: "80%",
          margin: "auto",
          marginTop: 30,
          marginBottom: 10,
          fontWeight: 500,
          backgroundColor: "#f2f2f2",
          borderRadius: 5,
          padding: 10,
        }}
      >
        Reviews
      </div>

      <Box sx={{ height: "800px", width: "80%", mx: "auto" }}>
        <ThemeProvider theme={theme}>
          <DataGrid
            slots={{
              toolbar: GridToolbar,
            }}
            rows={comments}
            // @ts-ignore
            columns={columnsComment}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default Contacts;
