import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "../../pages/Homepage";
import Listpage from "../../pages/Listpage";
import NotFoundpage from "../../pages/NotFoundpage";
import SinglePropertypage from "../../pages/SinglePropertypage";
import { useSelector, useDispatch } from "react-redux";
import { fetchProperties } from "../../redux/reducers/properties.slice";
import { fetchAdvertisements } from '../../redux/reducers/advertisements.slice';
import { useEffect } from "react";
import Results from '../../pages/Resultspage';
import Aboutuspage from '../../pages/Aboutuspage';
import ContactUspage from '../../pages/ContactUspage'; 
import Careerspage from '../../pages/Careerspage';
import PrivacyPolicypage from '../../pages/PrivacyPolicypage';
import Disclaimerpage from '../../pages/Disclaimerpage';
import Login from "../../pages/Login";
import Signup from "../../pages/Signup";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import OtpLogin from "../../pages/OtpLogin";
import ChatBot from "../SingleProperty/ChatBot";
import Contacts from "../../pages/admin/Contacts";
import Comments from "../../pages/admin/Comments";
import Users from "../../pages/admin/Users";
import Enquiries from "../../pages/admin/Enquiries";
import Form from "../../pages/admin/Form";
import EditForm from "../../pages/admin/EditForm";
import AdminLogin from "../../pages/admin/AdminLogin";
import ProtectedRoute from "./ProtectedRoute";
import AdminRegister from "../../pages/admin/AdminRegister";
import CategoryListpage from "../../pages/CategoryListpage";
function Public() {
    const properties = useSelector(state => state.properties);
    console.log(properties)
    const advertisements = useSelector(state => state.advertisements);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchProperties());
        dispatch(fetchAdvertisements());
        //eslint-disable-next-line
    }, []);
    return (
        <>
        <ToastContainer/>
        <Router>
            <Routes>
            <Route path="/admin/login" element={<AdminLogin />} />
        <Route
          path="/admin/property/edit/:id"
          element={<ProtectedRoute component={EditForm} />}
        />
        <Route path="/admin/" element={<ProtectedRoute component={Contacts} />} />
        <Route path="/admin/comments" element={<ProtectedRoute component={Comments} />} />
        <Route path="/admin/users" element={<ProtectedRoute component={Users} />} />
        <Route path="/admin/register" element={<ProtectedRoute component={AdminRegister} />} />
        <Route path="/admin/enquiries" element={<ProtectedRoute component={Enquiries} />} />
        <Route path="/admin/form" element={<ProtectedRoute component={Form} />} />
          <Route path='/' element={<Homepage data={properties} ad={advertisements.data.advertisements} />} />
                <Route path='/results' element={<Results data={properties} />} />
                <Route path='/list' element={<Listpage data={properties} />} />
                <Route path='/category' element={<CategoryListpage data={properties} />} />
                <Route path="/property/:pid" element={<SinglePropertypage data={properties} />} />
                <Route path="/about" element={<Aboutuspage data={properties} />} />
                <Route path="/contact" element={<ContactUspage data={properties} />} />
                <Route path="/careers" element={<Careerspage data={properties} />} />
                <Route path="/privacy" element={<PrivacyPolicypage data={properties} />} />
                <Route path="/disclaimer" element={<Disclaimerpage data={properties} />} />
                <Route path="/login" element={<Login data={properties} />} />
                <Route path="/signup" element={<Signup data={properties} />} />
                <Route path="/otplogin" element={<OtpLogin data={properties} />} />
                <Route path='/chatbot' element={<ChatBot/>}/>
                <Route data={properties} path='*' element={<NotFoundpage />} />
            </Routes>
        </Router>
        </>
    );
}

export default Public;