import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; // Import the 'js-cookie' library

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const navigate = useNavigate();

  const isAuthenticatedAdmin = Cookies.get('authCookie') === 'true';

  if (!isAuthenticatedAdmin) {
    navigate('/admin/login'); // Redirect to the admin login page
    return null;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
