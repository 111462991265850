import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AdminHeader from "./AdminHeader";
import {baseURL} from "./data";

const Enquiries = () => {
  const theme = createTheme({
    mode: "dark", // Set to "dark" for dark mode, if needed
    // Other theme properties...
  });
  const [enquiries, setEnquiry] = useState([]);
  const SetAllEnquiries = async () => {
    const res = await fetch(
      `${baseURL}admin/getAllEnquiries`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const jack = await res.json();
    var hmm = 0;
    setEnquiry(
      jack.enquiries.map((e) => {
        hmm += 1;
        return { ...e, id: hmm };
      })
    );
    console.log(jack, jack.enquiries, "USERINFO");
  };
  const columns = [
    { field: "id", headerName: "ID", width: 33 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      headerAlign: "left",
      align: "left",
      flex: 1,
      width: "10%",
    },
    {
      field: "property_name",
      headerName: "Property Name",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 3,
      width: "10%",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <button
          onClick={() => handleButtonClick(params.row)}
          style={{
            background: params.row.status ? "#7d9c53" : "red",
            padding: ".3rem",
            borderRadius: ".2rem",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          {params.row.status ? "Resolved" : "Pending"}
        </button>
      ),
    },
  ];
  const handleButtonClick = (row) => {
    row.status = !row.status;

    // Make an API request to update the "allowed" field in the database
    fetch(`${baseURL}resolveEnquiry`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: row._id }),
    })
      .then((response) => response.json())
      .then((updatedRow) => {
        // Handle the response if needed
        console.log("Updated Row:", updatedRow);
      })
      .catch((error) => {
        // Handle the error if the API request fails
        console.error("API Error:", error);
      });
  };
  useEffect(() => {
    SetAllEnquiries();
  }, [0]);
  return (
    <Box>
      <AdminHeader />
      <div
        className="text-left"
        style={{
          fontSize: 30,
          width: "80%",
          margin: "auto",
          marginTop: 30,
          marginBottom: 10,
          fontWeight: 500,
          backgroundColor: "#f2f2f2",
          borderRadius: 5,
          padding: 10,
        }}
      >
        Enquiries
      </div>

      <Box sx={{ height: "800px", width: "80%", mx: "auto" }}>
        <ThemeProvider theme={theme}>
          <DataGrid
            slots={{
              toolbar: GridToolbar,
            }}
            rows={enquiries}
            // @ts-ignore
            columns={columns}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default Enquiries;
