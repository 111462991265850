import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Box, Drawer, Stack, Button,HStack,DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Heading, Image, Link, LinkBox, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure, VStack, Flex } from '@chakra-ui/react';
import React,{useEffect,useState} from 'react';
import jsonData from '../constants/data.json';
import logo from "./Assets/logo.png"
import axios from 'axios'
import { RiWhatsappFill, RiPhoneFill } from 'react-icons/ri';
import { setLogout } from "../../src/redux/reducers/authslice";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropertyCard from './Cards/PropertyCard';



function Header({data}) {
    //console.log(data, 'Header')
    const [properties, setproperties] = useState([])
    const [showProperties, setShowProperties] = useState([])
    const [hidden, setHidden] = useState(0);

    const NavMenu = () => (
    
        <>
                                     <Link href='/category'  display={'flex'} flexWrap={'nowrap'} justifyContent={'space-between'}>
                <Text style={{fontSize:'16px'}} 
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.subcategory === "upcoming";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                                                >
                    Upcoming Projects
                </Text>
                    <KeyboardArrowDownIcon />
            </Link>
            <Link href='/category'  display={'flex'} flexWrap={'nowrap'} justifyContent={'space-between'}>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.subcategory === "new launch";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                
                >
                    New Launch
                </Text>
                    <KeyboardArrowDownIcon />
            </Link>
            <Link href='/category'  display={'flex'} flexWrap={'nowrap'} justifyContent={'space-between'}>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.subcategory === "under construction";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                    Under Construction
                </Text>
                    <KeyboardArrowDownIcon />
            </Link>
            <Link href='/category'  display={'flex'} flexWrap={'nowrap'} justifyContent={'space-between'}>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.propertyCategory === "apartments";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                    Apartments
                </Text>
                    <KeyboardArrowDownIcon />
            </Link>
            <Link href='/category'  display={'flex'} flexWrap={'nowrap'} justifyContent={'space-between'}
            onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.propertyCategory === "villas";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                <Text style={{fontSize:'16px'}}>
                    Villas
                </Text>
                    <KeyboardArrowDownIcon />
            </Link>
            <Link href='/category'  display={'flex'} flexWrap={'nowrap'} justifyContent={'space-between'}>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.propertyCategory === "plots";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                    Plots
                </Text>
                    <KeyboardArrowDownIcon />
            </Link>
            <Link href='/category'  display={'flex'} flexWrap={'nowrap'} justifyContent={'space-between'}>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.propertyCategory === "townships";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                    Townships
                </Text>
                    <KeyboardArrowDownIcon />
            </Link>
            
            <Link href='/signup' >
                <Text style={{fontSize:'16px'}}>
                    Login
                </Text>
            </Link>
        </>
        
    );
    useEffect(()=>{
        setproperties(data?.data.properties)
        setShowProperties(properties)
        console.log(properties, 'intheset');
      }, [data])
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [name,setname] = useState('')
    const { isAuthenticated } = useSelector((state) => ({ ...state.authSlice }));
    const user = useSelector((state) => ({...state.authSlice}));
    useEffect(()=>{
        console.log(user, "user state")
        setname(user?.user?.data?.result?.Name);
    },[0])

    //console.log(user, "nani")
    //console.log(isAuthenticated);

    const logout = ()=>{
        dispatch(setLogout());
        navigate("/signup")
        toast.success("Logout successfully")
      }

    return (
        <VStack position={'sticky'} top='0' zIndex={'1000'} onMouseLeave={()=>{setHidden(0)}}>
        <Box w={"100%"}  style={{color:"white",fontWeight:600,display:"flex", justifyContent:"space-between",textTransform:"uppercase",fontSize:"21px",fontFamily: "Poppins",background: "linear-gradient(90deg, rgba(142, 176, 93, 0.00) 0%, #8EB05D 27.1%)"}} 
            boxShadow='dark-lg'
            bgColor={'white'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}
        >

            <Link href="/">
                <Image
                    width="80px"
                    m='0'
                    src={logo}
                    alt='' />
            </Link>

            <Box display={'flex'} justifyContent={'right'} w='100%'>
                

                <Box display={['none', 'none', 'flex']} justifyContent={'space-between'} alignItems={'center'} gap='4' pr={'10'}>
                    {
                        isAuthenticated?
                       <>
                                                      <Link href='/category'>
                <Text style={{fontSize:'16px'}} 
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.subcategory === "upcoming";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                                                >
                    Upcoming Projects
                </Text>
                    <KeyboardArrowDownIcon />
            </Link>
            <Link href='/category'>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.subcategory === "new launch";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                
                >
                    New Launch
                    <KeyboardArrowDownIcon />
                </Text>
            </Link>
            <Link href='/category'>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.subcategory === "under construction";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                    Under Construction
                    <KeyboardArrowDownIcon />
                </Text>
            </Link>
            <Link href='/category'>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.propertyCategory === "apartments";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                    Apartments
                    <KeyboardArrowDownIcon />
                </Text>
            </Link>
            <Link href='/category'
            onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.propertyCategory === "villas";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                <Text style={{fontSize:'16px'}}>
                    Villas
                    <KeyboardArrowDownIcon />
                </Text>
            </Link>
            <Link href='/category'>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.propertyCategory === "plots";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                    Plots
                    <KeyboardArrowDownIcon />
                </Text>
            </Link>
            <Link href='/category'>
                <Text style={{fontSize:'16px'}}
                onMouseEnter={()=>{setShowProperties(properties.filter((p, i) => {
                                                return p.propertyCategory === "townships";
                                                }));console.log('hi',showProperties, "kjfdkjdfkj");setHidden(1)}}
                >
                    Townships
                    <KeyboardArrowDownIcon />
                </Text>
            </Link>
        <Link href='/'>
            <Text fontSize='xl'>
                Welcome({name})
            </Text>
        </Link>
        <Link href='/'>
            <Text fontSize='xl' onClick={logout}>
                Logout
            </Text>
        </Link>
                       </>:
                        <NavMenu />
                    }
                </Box>

                <Box display={['block', 'block', 'none']}>
                    <Box display={['flex']} alignItems='center'>
                        <HamburgerIcon
                            w='7' h='7'
                            onClick={onOpen}
                            display={['block', 'block', 'none']}
                            mr={'3'}
                        />
                    </Box>
                    <Drawer
                        isOpen={isOpen}
                        placement='right'
                        onClose={onClose}
                    >
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerBody m='10%'>
                                <Box
                                    display={'flex'}
                                    flexDir={'column'}
                                    justifyContent='space-between'
                                    alignItems={'left'}
                                    gap='4'
                                    fontWeight={'black'}
                                    fontSize='lg'
                                >
                                    <Link href='/'>
                                    <Box>
                                        <Image
                                            boxSize={'150px'}
                                            m='1'
                                            objectFit='contain'
                                            src={logo}
                                            alt='' />
                                    </Box>
                                            </Link>
                                    <NavMenu />
                                </Box>
                            </DrawerBody>
                            <DrawerFooter>
                                <Box p='7'>
                                    <Heading
                                        fontSize={'md'}
                                    >Call us at +91 7090667009</Heading>
                                </Box>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </Box>
            </Box>

        </Box >
        
        {hidden&&showProperties?.length &&<HStack background={'white'} display={['none','none','block']}>
            <Flex w={'100vw'} overflowX={'auto'}
            >
                {showProperties?.map((d, i)=>{
                    return <Box
                    m='2'
                    minW='280px'
                    maxW={'fit-content'}
                    w={'full'}
                    bg={'white'}
                    boxShadow={['md','xl','2xl']}
                    rounded={'md'}
                    p={['1.5', '2', '3']}
                    overflow={'hidden'} 
                    position='relative'>
                    <Box position='absolute'
                      top='4' left='4'
                      backgroundColor='red.500'
                      color='white'
                      textTransform={'capitalize'}
                      fontWeight='extrabold'
                      borderRadius='sm'
                      p='1'>
                      {d?.subcategory}
                    </Box>
                    <Box height='150'>
                      <Image
                        cursor='pointer'
                        onClick={() => {
                          navigate(`/property/${d?._id}`);
                        }}
                        height='150'  src={d?.card_image_url || 'https://ik.imagekit.io/q8qsfnr9wag/ezgif.com-gif-maker_6r8M6zUPL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652557196270'}
                        alt=""
                      />
                    </Box>
                    <Stack>
                      <Heading
                        color={'gray.700'}
                        fontSize={'xl'} mt='2' textTransform='capitalize'>
                        {d?.title}
                      </Heading>
                      <Text
                        color={'secondaryBg'}
                        textTransform={'capitalize'}
                        fontWeight={800}
                        fontSize={'sm'}
                        letterSpacing={1.1}>
                        {d?.location}
                      </Text>
                      
                    </Stack>
                  </Box>
                })}
            </Flex>
        </HStack>
        }
                {hidden&&!showProperties?.length && <Box display={['none','none','block']}>No Properties available for this category</Box>}

        </VStack>
    );
}

export default Header;