import React from 'react';
import { Box, Flex, Text,VStack, Heading, Button } from "@chakra-ui/react";
import { Link, useNavigate } from 'react-router-dom';
// import jsonData from '../../constants/data.json';
import apartments from '../Assets/apartments.png'
import villas from '../Assets/villas.png'
import plots from '../Assets/plots.png'
import townships from '../Assets/townships.png'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
function Categories() {
  const navigate = useNavigate();
  return (
    <>
    <Heading display={['block','none','none']} paddingLeft={['10%',0,0]} paddingTop={['10%',0,0]} textAlign={['left','center','center']}>Explore Now</Heading>
    <Text textAlign={['left','center','center']} paddingLeft={['10%',0,0]} display={['none','flex','flex']} fontWeight={"600"} marginTop={"2rem"} marginBottom={"1.5rem"} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} fontSize={"2.25rem"}>
    <Heading textAlign={['left','center','center']}>Explore Now</Heading>
        <hr display={['none','block','block']} width="6%" style={{border:"2px solid #92B161"}}/>
      </Text>
    <Flex w={'100vw'} overflowX={'auto'}
    >
    <Box color={"#92B161"} >
      <Link to='/category'>
      <Flex flexDir={['row', 'row', 'row']} justifyContent='space-around' gap='' overflowX={'auto'}>
        <VStack style={{display:"flex", justifyContent:"center", alignItems:"center"}} padding="3">
        <ChevronLeftIcon style={{color:"rgb(38 41 33)",borderRadius:"50%", background:"rgb(200 216 176)"}} />
        </VStack>
        
        <VStack p='4' w={'20%'} key={1}>
          <img width="100%" src={apartments} alt="" />
          <Text fontWeight={'600'} fontSize='xl'>Apartments</Text>
        </VStack>
        <VStack p='4' w={'20%'} key={2}>
          <img width="100%" src={villas} alt="" />
          <Text fontWeight={'600'} fontSize='xl'>Villas</Text>
        </VStack>
        <VStack p='4' w={'20%'} key={3}>
          <img width="100%" src={plots} alt="" />
          <Text fontWeight={'600'} fontSize='xl'>Plots</Text>
        </VStack>
        <VStack p='4' w={'20%'} key={4}>
          <img width="100%" src={townships} alt="" />
          <Text fontWeight={'600'} fontSize='xl'>Townships</Text>
        </VStack>
        <VStack style={{display:"flex", justifyContent:"center", alignItems:"center"}} padding="3">
        <ChevronRightIcon style={{color:"rgb(38 41 33)",borderRadius:"50%", background:"rgb(200 216 176)"}} />
        </VStack>
        </Flex>
        </Link>
    </Box>
    </Flex>
    </>
  );
}

export default Categories;