import React from "react";
import HamburgerMenu from "../../components/Hamburger";
const AdminHeader = () => {
  return (
    <div>
      <div
        style={{ padding: 10, fontSize: 25, marginBottom: 5 }}
        className="text-center w-full shadow-md"
      >
        <HamburgerMenu />
        Admin Panel
      </div>
    </div>
  );
};

export default AdminHeader;
