import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { FormControl, TextField } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Input } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import { Button } from "@material-ui/core";
import AdminHeader from "./AdminHeader";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {baseURL} from "./data";
import {useToast } from '@chakra-ui/react';

function EditForm(props) {
  const { id } = useParams();
  const [property, setProperty] = useState({});

  const [projectDetails, setProjectDetails] = useState([
    {
      icon_url: "",
      heading: "",
      heading_value: "",
    },
  ]);

  const [hero_card, setHero_card] = useState({
    heading: "",
    location: "",
    builder_name: "",
    open_space: "",
    units: "",
    possession: "",
    nearby_location: "",
    line1_data: "",
    line2_data: "",
    line3_data: "",
    line4_data: "",
    line5_data: "",
  });
  const [overview, setOverview] = useState({
    heading: "",
    content: "",
  });
  const [newVideo, setNewVideo] = useState(""); // Store the new video input

  const handleVideoChange = (e) => {
    setNewVideo(e.target.value);
  };
  const [newGallery, setNewGallery] = useState(""); // Store the new video input

  const handleGalleryChange = (e) => {
    setNewGallery(e.target.value);
  };
  const [unitAndPrice, setUnitAndPrice] = useState([
    {
      type: "",
      subtype: "",
      sbua: "",
      price: "",
      floor_plan_img: "",
    },
  ]);
  const [floorPlans, setFloorPlans] = useState([
    {
      img_url: "",
      title: "",
    },
  ]);
  const [amenities, setAmenities] = useState([
    {
      title: "",
      img_url: "",
    },
  ]);
  const [masterPlanGallery, setMasterPlanGallery] = useState([""]);
  const [locationMapGallery, setLocationMapGallery] = useState([""]);
  const [masterPlanImgUrl, setMasterPlanImgUrl] = useState("");
  const [ytLink, setYtLink] = useState("");
  const [faqs, updatefaqs] = useState({
    faq1: {
      q: "",
      a: "",
    },
    faq2: {
      q: "",
      a: "",
    },
    faq3: {
      q: "",
      a: "",
    },
    faq4: {
      q: "",
      a: "",
    },
    faq5: {
      q: "",
      a: "",
    },
    faq6: {
      q: "",
      a: "",
    },
    faq7: {
      q: "",
      a: "",
    },
    faq8: {
      q: "",
      a: "",
    },
  });
  const [locationSection, setLocationSection] = useState({
    map_link: "",
    map_img_url: "",
    content: "",
  });
  const [aboutSection, setAboutSection] = useState({
    heading: "",
    builder_logo_url: "",
    content: "",
    ongoing: "",
    upcoming: "",
    completed: "",
  });
  const [locationPoints, setLocationPoints] = useState({
    hospital: [""],
    schools: [""],
    landmarks: [""],
    nearby: [""],
    others: [""],
  });
  const [headerBtnColorCodes, setHeaderBtnColorCodes] = useState({
    under_construction: {
      subcategory: "under construction",
      color_code: "",
    },
    new_launch: {
      subcategory: "new launch",
      color_code: "",
    },
    ready_to_move_in: {
      subcategory: "ready to move in",
      color_code: "",
    },
    possession: {
      subcategory: "possession",
      color_code: "",
    },
  });
  const [structure, setStructure] = useState({
    heading: "",
    img_url: "",
    content: [""],
  });
  const [flooring, setFlooring] = useState({
    heading: "",
    img_url: "",
    content: [""],
  });
  const [electrical, setElectrical] = useState({
    heading: "",
    img_url: "",
    content: [""],
  });
  const [doors, setDoors] = useState({
    heading: "",
    img_url: "",
    content: [""],
  });
  const [plumbing_and_sanitary, setPlumbing_and_sanitary] = useState({
    heading: "",
    img_url: "",
    content: [""],
  });
  const [security, setSecurity] = useState({
    heading: "",
    img_url: "",
    content: [""],
  });
  const [specifications, setSpecifications] = useState({
    heading: "",
    content: {
      structure: structure,
      flooring: flooring,
      doors: doors,
      plumbing_and_sanitary: plumbing_and_sanitary,
      security: security,
      electrical: electrical,
    },
  });
  const [formData, setFormData] = useState({
    title: "",
    location: "",
    units: "",
    description: "",
    propertyCategory:"",
    price: "",
    location_points: locationPoints,
    card_image_url: "",
    unitVariants: "",
    hero_img_url: "",
    logo_url: "",
    yt_videos: [],
    gallerySliderImages: [],
    new_launch: "",
    hero_img_title: "",
    category: "",
    projectDetails: projectDetails,
    hero_card: hero_card,
    overview: overview,
    unit_and_price: unitAndPrice,
    floor_plans: floorPlans,
    amenities: amenities,
    specifications: specifications,
    location_section: locationSection,
    location_map_gallery: locationMapGallery,
    master_plan_gallery: masterPlanGallery,
    about_section: aboutSection,
    header_btn_color_codes: headerBtnColorCodes,
  });
  const getProperty = async () => {
    const res = await fetch(`${baseURL}properties/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jack = await res.json();
    var hmm = 0;
    setProperty(jack.propertyDetails);
    setFormData(jack.propertyDetails);
    setHero_card(jack.propertyDetails.hero_card);
    setOverview(jack.propertyDetails.overview);
    setProjectDetails(jack.propertyDetails.projectDetails);
    setUnitAndPrice(jack.propertyDetails.unit_and_price);
    setFloorPlans(jack.propertyDetails.floor_plans);
    setAmenities(jack.propertyDetails.amenities);
    setMasterPlanGallery(jack.propertyDetails.master_plan_gallery);
    setLocationMapGallery(jack.propertyDetails.location_map_gallery);
    updatefaqs(jack.propertyDetails.faqs);
    setLocationSection(jack.propertyDetails.location_section);
    setAboutSection(jack.propertyDetails.about_section);
    setLocationPoints(jack.propertyDetails.location_points);
    setHeaderBtnColorCodes(jack.propertyDetails.header_btn_color_codes);
    setStructure(jack.propertyDetails.specifications.content.structure);
    setFlooring(jack.propertyDetails.specifications.content.flooring);
    setElectrical(jack.propertyDetails.specifications.content.electrical);
    setDoors(jack.propertyDetails.specifications.content.doors);
    setPlumbing_and_sanitary(
      jack.propertyDetails.specifications.content.plumbing_and_sanitary
    );
    setSecurity(jack.propertyDetails.specifications.content.security);
    setSpecifications(jack.propertyDetails.specifications);
    setMasterPlanImgUrl(jack.propertyDetails.master_plan_img_url);
    setYtLink(jack.propertyDetails.yt_link);
    console.log(jack, "UINFO", property);
  };
  useEffect(() => {
    getProperty();
  }, [0]);

  console.log(property, formData, "khfdfhdg");

  const handleAboutSectionChange = (field, value) => {
    setAboutSection({
      ...aboutSection,
      [field]: value,
    });
    setFormData({ ...formData, about_section: aboutSection });
  };

  const handleLocationPointsChange = (subfield, index, value) => {
    setLocationPoints((prevLocationPoints) => ({
      ...prevLocationPoints,
      [subfield]: prevLocationPoints[subfield].map((item, i) =>
        i === index ? value : item
      ),
    }));
    setFormData({ ...formData, location_points: locationPoints });
  };
  const handleColorCodeChange = (subcategory, colorCode) => {
    setHeaderBtnColorCodes((prevColorCodes) => ({
      ...prevColorCodes,
      [subcategory]: { ...prevColorCodes[subcategory], color_code: colorCode },
    }));
    setFormData({ ...formData, header_btn_color_codes: headerBtnColorCodes });
  };
  const handleAddLocationPoint = (subfield) => {
    setLocationPoints((prevLocationPoints) => ({
      ...prevLocationPoints,
      [subfield]: [...prevLocationPoints[subfield], ""],
    }));

    setFormData({ ...formData, location_points: locationPoints });
  };
  const handleStructureHeadingChange = (event) => {
    const newValue = event.target.value;
    setStructure({
      ...structure,
      heading: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, structure: structure },
    });

    setFormData({ ...formData, specifications: specifications });
  };

  const handleStructureImgUrlChange = (event) => {
    const newValue = event.target.value;
    setStructure({
      ...structure,
      img_url: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, structure: structure },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const handleStructureContentChange = (event, index) => {
    const newValue = event.target.value;
    const updatedContent = structure.content;
    updatedContent[index] = newValue;

    setSpecifications({
      ...specifications,
      content: { ...specifications.content, structure: structure },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const addStructureContentField = () => {
    setStructure({
      ...structure,
      content: [...structure.content, ""],
    });
  };
  //////////////////////////////////
  const handleFlooringHeadingChange = (event) => {
    const newValue = event.target.value;
    setFlooring({
      ...flooring,
      heading: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, flooring: flooring },
    });

    setFormData({ ...formData, specifications: specifications });
  };

  const handleFlooringImgUrlChange = (event) => {
    const newValue = event.target.value;
    setFlooring({
      ...flooring,
      img_url: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, flooring: flooring },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const handleFlooringContentChange = (event, index) => {
    const newValue = event.target.value;
    const updatedContent = flooring.content;
    updatedContent[index] = newValue;

    setSpecifications({
      ...specifications,
      content: { ...specifications.content, flooring: flooring },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const addFlooringContentField = () => {
    setFlooring({
      ...flooring,
      content: [...flooring.content, ""],
    });
  };
  //////////////////////////////////////
  const handleElectricalHeadingChange = (event) => {
    const newValue = event.target.value;
    setElectrical({
      ...electrical,
      heading: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, electrical: electrical },
    });

    setFormData({ ...formData, specifications: specifications });
  };

  const handleElectricalImgUrlChange = (event) => {
    const newValue = event.target.value;
    setElectrical({
      ...electrical,
      img_url: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, electrical: electrical },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const handleElectricalContentChange = (event, index) => {
    const newValue = event.target.value;
    const updatedContent = electrical.content;
    updatedContent[index] = newValue;

    setSpecifications({
      ...specifications,
      content: { ...specifications.content, electrical: electrical },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const addElectricalContentField = () => {
    setElectrical({
      ...electrical,
      content: [...electrical.content, ""],
    });
  };
  /////////////////////////////
  const handleDoorsHeadingChange = (event) => {
    const newValue = event.target.value;
    setDoors({
      ...doors,
      heading: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, doors: doors },
    });

    setFormData({ ...formData, specifications: specifications });
  };

  const handleDoorsImgUrlChange = (event) => {
    const newValue = event.target.value;
    setDoors({
      ...doors,
      img_url: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, doors: doors },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const handleDoorsContentChange = (event, index) => {
    const newValue = event.target.value;
    const updatedContent = doors.content;
    updatedContent[index] = newValue;

    setSpecifications({
      ...specifications,
      content: { ...specifications.content, doors: doors },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const addDoorsContentField = () => {
    setDoors({
      ...doors,
      content: [...doors.content, ""],
    });
  };
  /////////////////////////////
  const handlePlumbing_and_sanitaryHeadingChange = (event) => {
    const newValue = event.target.value;
    setPlumbing_and_sanitary({
      ...plumbing_and_sanitary,
      heading: newValue,
    });
    setSpecifications({
      ...specifications,
      content: {
        ...specifications.content,
        plumbing_and_sanitary: plumbing_and_sanitary,
      },
    });

    setFormData({ ...formData, specifications: specifications });
  };

  const handlePlumbing_and_sanitaryImgUrlChange = (event) => {
    const newValue = event.target.value;
    setPlumbing_and_sanitary({
      ...plumbing_and_sanitary,
      img_url: newValue,
    });
    setSpecifications({
      ...specifications,
      content: {
        ...specifications.content,
        plumbing_and_sanitary: plumbing_and_sanitary,
      },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const handlePlumbing_and_sanitaryContentChange = (event, index) => {
    const newValue = event.target.value;
    const updatedContent = plumbing_and_sanitary.content;
    updatedContent[index] = newValue;

    setSpecifications({
      ...specifications,
      content: {
        ...specifications.content,
        plumbing_and_sanitary: plumbing_and_sanitary,
      },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const addPlumbing_and_sanitaryContentField = () => {
    setPlumbing_and_sanitary({
      ...plumbing_and_sanitary,
      content: [...plumbing_and_sanitary.content, ""],
    });
  };
  ////////////////////////////////////
  const handleSecurityHeadingChange = (event) => {
    const newValue = event.target.value;
    setSecurity({
      ...security,
      heading: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, security: security },
    });

    setFormData({ ...formData, specifications: specifications });
  };

  const handleSecurityImgUrlChange = (event) => {
    const newValue = event.target.value;
    setSecurity({
      ...security,
      img_url: newValue,
    });
    setSpecifications({
      ...specifications,
      content: { ...specifications.content, security: security },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const handleSecurityContentChange = (event, index) => {
    const newValue = event.target.value;
    const updatedContent = security.content;
    updatedContent[index] = newValue;

    setSpecifications({
      ...specifications,
      content: { ...specifications.content, security: security },
    });
    setFormData({ ...formData, specifications: specifications });
  };

  const addSecurityContentField = () => {
    setSecurity({
      ...security,
      content: [...security.content, ""],
    });
  };
  //////////////////////////////////////

  const handleRemoveLocationPoint = (subfield, index) => {
    setLocationPoints((prevLocationPoints) => ({
      ...prevLocationPoints,
      [subfield]: prevLocationPoints[subfield].filter((item, i) => i !== index),
    }));

    setFormData({ ...formData, location_points: locationPoints });
  };

  const handleYtLinkChange = (value) => {
    setYtLink(value);

    // You can update formData here.
    setFormData({ ...formData, yt_link: value });
  };
  const handleAddDetail = () => {
    console.log(formData);
    setProjectDetails([
      ...projectDetails,
      {
        icon_url: "",
        heading: "",
        heading_value: "",
      },
    ]);
    setFormData({ ...formData, projectDetails: projectDetails });
    console.log(projectDetails);
  };
  const handleFaqChange = (faqIndex, field, value) => {
    const updatedFaqs = { ...faqs };
    updatedFaqs[`faq${faqIndex}`][field] = value;
    updatefaqs(updatedFaqs);
    setFormData({ ...formData, faqs: faqs });
  };
  const handleLocationSectionChange = (field, value) => {
    setLocationSection({
      ...locationSection,
      [field]: value,
    });
    setFormData({ ...formData, location_section: locationSection });
  };
  const handleRemoveDetail = (index) => {
    const updatedDetails = [...projectDetails];
    updatedDetails.splice(index, 1);
    setProjectDetails(updatedDetails);
    setFormData({ ...formData, projectDetails: projectDetails });
  };

  const handleUpdate = () => {
    // You can access the updated projectDetails array here.
    console.log(projectDetails);
  };

  const handleDetailChange = (index, field, value) => {
    setProjectDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index][field] = value;
      return updatedDetails;
    });
    console.log(formData, index, field, value);
  };
  const handleAddUnitAndPrice = () => {
    setUnitAndPrice((prevUnitAndPrice) => [
      ...prevUnitAndPrice,
      {
        type: "",
        subtype: "",
        sbua: "",
        price: "",
        floor_plan_img: "",
      },
    ]);
    setFormData({ ...formData, unit_and_price: unitAndPrice });
    console.log(formData);
  };

  const handleRemoveUnitAndPrice = (index) => {
    const updatedUnitAndPrice = [...unitAndPrice];
    updatedUnitAndPrice.splice(index, 1);
    setUnitAndPrice(updatedUnitAndPrice);
    setFormData({ ...formData, unit_and_price: unitAndPrice });
  };

  const handleUpdateUnitAndPrice = () => {
    // You can access the updated unitAndPrice array here.
    console.log(unitAndPrice);
  };

  const handleChangeUnitAndPrice = (index, field, value) => {
    setUnitAndPrice((prevUnitAndPrice) => {
      const updatedUnitAndPrice = [...prevUnitAndPrice];
      updatedUnitAndPrice[index][field] = value;
      return updatedUnitAndPrice;
    });
  };
  const handleAddFloorPlan = () => {
    setFloorPlans((prevFloorPlans) => [
      ...prevFloorPlans,
      {
        img_url: "",
        title: "",
      },
    ]);
    setFormData({ ...formData, floor_plans: floorPlans });
    console.log(formData);
  };
  console.log(formData);
  const handleRemoveFloorPlan = (index) => {
    const updatedFloorPlans = [...floorPlans];
    updatedFloorPlans.splice(index, 1);
    setFloorPlans(updatedFloorPlans);

    setFormData({ ...formData, floor_plans: floorPlans });
  };

  const handleUpdateFloorPlans = () => {
    // You can access the updated floorPlans array here.
    console.log(floorPlans);
  };

  const handleFloorPlanChange = (index, field, value) => {
    setFloorPlans((prevFloorPlans) => {
      const updatedFloorPlans = [...prevFloorPlans];
      updatedFloorPlans[index][field] = value;
      return updatedFloorPlans;
    });
  };
  const handleAddAmenity = () => {
    setAmenities((prevAmenities) => [
      ...prevAmenities,
      {
        title: "",
        img_url: "",
      },
    ]);
    setFormData({ ...formData, amenities: amenities });
  };

  const handleRemoveAmenity = (index) => {
    const updatedAmenities = [...amenities];
    updatedAmenities.splice(index, 1);
    setAmenities(updatedAmenities);
    setFormData({ ...formData, amenities: amenities });
  };

  const handleUpdateAmenities = () => {
    // You can access the updated amenities array here.
    console.log(amenities);
  };

  const handleAmenityChange = (index, field, value) => {
    setAmenities((prevAmenities) => {
      const updatedAmenities = [...prevAmenities];
      updatedAmenities[index][field] = value;
      return updatedAmenities;
    });
  };
  const handleAddMasterPlan = () => {
    setMasterPlanGallery((prevMasterPlanGallery) => [
      ...prevMasterPlanGallery,
      "",
    ]);
    setFormData({ ...formData, master_plan_gallery: masterPlanGallery });
  };

  const handleRemoveMasterPlan = (index) => {
    const updatedMasterPlanGallery = [...masterPlanGallery];
    updatedMasterPlanGallery.splice(index, 1);
    setMasterPlanGallery(updatedMasterPlanGallery);
    setFormData({ ...formData, master_plan_gallery: masterPlanGallery });
  };

  const handleUpdateMasterPlanGallery = () => {
    // You can access the updated masterPlanGallery array here.
    console.log(masterPlanGallery);
  };

  const handleMasterPlanChange = (index, value) => {
    setMasterPlanGallery((prevMasterPlanGallery) => {
      const updatedMasterPlanGallery = [...prevMasterPlanGallery];
      updatedMasterPlanGallery[index] = value;
      return updatedMasterPlanGallery;
    });
    setFormData({ ...formData, master_plan_gallery: masterPlanGallery });
  };
  const handleAddLocationMap = () => {
    setLocationMapGallery((prevLocationMapGallery) => [
      ...prevLocationMapGallery,
      "",
    ]);
    setFormData({ ...formData, location_map_gallery: locationMapGallery });
  };

  const handleRemoveLocationMap = (index) => {
    const updatedLocationMapGallery = [...locationMapGallery];
    updatedLocationMapGallery.splice(index, 1);
    setLocationMapGallery(updatedLocationMapGallery);
    setFormData({ ...formData, location_map_gallery: locationMapGallery });
  };

  const handleUpdateLocationMapGallery = () => {
    // You can access the updated locationMapGallery array here.
    console.log(locationMapGallery);
  };

  const handleLocationMapChange = (index, value) => {
    setLocationMapGallery((prevLocationMapGallery) => {
      const updatedLocationMapGallery = [...prevLocationMapGallery];
      updatedLocationMapGallery[index] = value;
      return updatedLocationMapGallery;
    });
    setFormData({ ...formData, location_map_gallery: locationMapGallery });
  };
  const handleMasterPlanImgUrlChange = (value) => {
    setMasterPlanImgUrl(value);

    // You can update formData here.
    setFormData({ ...formData, master_plan_img_url: value });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };
  const handleHeroChange = (e) => {
    const { name, value } = e.target;
    setHero_card({
      ...hero_card,
      [name.split("hero_card")[1]]: value,
    });
    setFormData({ ...formData, hero_card: hero_card });
    console.log(hero_card, name.split("hero_card")[1]);
  };
  const handleOverviewChange = (e) => {
    const { name, value } = e.target;
    setOverview({
      ...overview,
      [name.split("overview")[1]]: value,
    });
    setFormData({ ...formData, overview: overview });
    console.log(overview, name.split("overview")[1]);
  };
  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the formData to your backend (e.g., via API or a request library like Axios)
    // You can use fetch or Axios to send a POST request to your server.
    // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint.
    fetch(`${baseURL}properties/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {if(response.status == 201){toast({
        title: 'Success',
        description: "Your property has been updated successfully.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });}console.log(response.status);return response.json()})
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleAddVideo = () => {
    if (newVideo) {
      setFormData({
        ...formData,
        yt_videos: [...formData.yt_videos, newVideo],
      });
      setNewVideo(""); // Clear the input field after adding
    }
  };

  const handleRemoveVideo = (index) => {
    const updatedVideos = [...formData.yt_videos];
    updatedVideos.splice(index, 1);
    setFormData({
      ...formData,
      yt_videos: updatedVideos,
    });
  };
  const handleAddGallery = () => {
    console.log(formData);
    if (newGallery) {
      setFormData({
        ...formData,
        gallerySliderImages: [...formData.gallerySliderImages, newGallery],
      });
      setNewGallery(""); // Clear the input field after adding
    }
  };

  const handleRemoveGallery = (index) => {
    const updatedGallery = [...formData.gallerySliderImages];
    updatedGallery.splice(index, 1);
    setFormData({
      ...formData,
      gallerySliderImages: updatedGallery,
    });
  };
  return (
    <div>
      <AdminHeader />
      <div
        className="text-left"
        style={{
          fontSize: 30,
          width: "80%",
          margin: "auto",
          marginTop: 30,
          marginBottom: 10,
          fontWeight: 500,
          backgroundColor: "#f2f2f2",
          borderRadius: 5,
          padding: 10,
        }}
      >
        Update the Property
      </div>
      <div style={{ width: "100%" }}>
        <form
          onSubmit={handleSubmit}
          style={{
            width: "80%",
            margin: "auto",
            boxShadow: "2px 2px 10px black",
            marginBottom: "20px",
            padding: "20px",
          }}
        >
          <h2 className="text-2xl mb-4">Basic Details</h2>
          <div className="flex gap-3 flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="title">Title</InputLabel>
                <Input
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="location">Location</InputLabel>
                <Input
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="units">Units</InputLabel>
                <Input
                  id="units"
                  name="units"
                  value={formData.units}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="description">Description</InputLabel>
                <Input
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  multiline
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="propertyCategory">Property Category</InputLabel>
                <Input
                  id="propertyCategory"
                  name="propertyCategory"
                  value={formData.propertyCategory}
                  onChange={handleChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="card_image_url">Card Image Url</InputLabel>
                <Input
                  id="card_image_url"
                  name="card_image_url"
                  value={formData.card_image_url}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="price">Price</InputLabel>
                <Input
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="unitVariants">Unit Variants</InputLabel>
                <Input
                  id="unitVariants"
                  name="unitVariants"
                  value={formData.unitVariants}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="logo_url">Logo Url</InputLabel>
                <Input
                  id="logo_url"
                  name="logo_url"
                  value={formData.logo_url}
                  onChange={handleChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_img_url">Hero Img Url</InputLabel>
                <Input
                  id="hero_img_url"
                  name="hero_img_url"
                  value={formData.hero_img_url}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_img_title">Hero Img Title</InputLabel>
                <Input
                  id="hero_img_title"
                  name="hero_img_title"
                  value={formData.hero_img_title}
                  onChange={handleChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/3 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="new_launch">New Launch</InputLabel>
                <Input
                  id="new_launch"
                  name="new_launch"
                  value={formData.new_launch}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="category">Category</InputLabel>
                <Input
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  multiline
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="subcategory">Sub-Category</InputLabel>
                <Input
                  id="subcategory"
                  name="subcategory"
                  value={formData.subcategory}
                  onChange={handleChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <FormControl fullWidth>
              <InputLabel htmlFor="masterPlanImgUrl">
                Master Plan Image URL
              </InputLabel>
              <Input
                id="masterPlanImgUrl"
                value={masterPlanImgUrl}
                onChange={(e) => handleMasterPlanImgUrlChange(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="ytLink">YouTube Link</InputLabel>
              <Input
                id="ytLink"
                value={ytLink}
                onChange={(e) => handleYtLinkChange(e.target.value)}
              />
            </FormControl>
          </div>

          <br />
          <h2 className="text-2xl mt-2 mb-4">Hero Card</h2>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardheading">Hero Img Url</InputLabel>
                <Input
                  id="hero_cardheading"
                  name="hero_cardheading"
                  value={hero_card.heading}
                  onChange={handleHeroChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardlocation">
                  Hero Img Title
                </InputLabel>
                <Input
                  id="hero_cardlocation"
                  name="hero_cardlocation"
                  value={hero_card.location}
                  onChange={handleHeroChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardbuilder_name">
                  Builder Name
                </InputLabel>
                <Input
                  id="hero_cardbuilder_name"
                  name="hero_cardbuilder_name"
                  value={hero_card.builder_name}
                  onChange={handleHeroChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardopen_space">
                  Open Space
                </InputLabel>
                <Input
                  id="hero_cardopen_space"
                  name="hero_cardopen_space"
                  value={hero_card.open_space}
                  onChange={handleHeroChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardunits">Units</InputLabel>
                <Input
                  id="hero_cardunits"
                  name="hero_cardunits"
                  value={hero_card.units}
                  onChange={handleHeroChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardpossession">
                  Possession
                </InputLabel>
                <Input
                  id="hero_cardpossession"
                  name="hero_cardpossession"
                  value={hero_card.possession}
                  onChange={handleHeroChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardnearby_location">
                  Nearby Location
                </InputLabel>
                <Input
                  id="hero_cardnearby_location"
                  name="hero_cardnearby_location"
                  value={hero_card.nearby_location}
                  onChange={handleHeroChange}
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardline1_data">
                  Line 1 Data
                </InputLabel>
                <Input
                  id="hero_cardline1_data"
                  name="hero_cardline1_data"
                  value={hero_card.line1_data}
                  onChange={handleHeroChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardline2_data">
                  Line 2 Data
                </InputLabel>
                <Input
                  id="hero_cardline2_data"
                  name="hero_cardline2_data"
                  value={hero_card.line2_data}
                  onChange={handleHeroChange}
                  multiline
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardline3_data">
                  Line 3 Data
                </InputLabel>
                <Input
                  id="hero_cardline3_data"
                  name="hero_cardline3_data"
                  value={hero_card.line3_data}
                  onChange={handleHeroChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardline4_data">
                  Line 4 Data
                </InputLabel>
                <Input
                  id="hero_cardline4_data"
                  name="hero_cardline4_data"
                  value={hero_card.line4_data}
                  onChange={handleHeroChange}
                  multiline
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="hero_cardline5_data">
                  Line 5 Data
                </InputLabel>
                <Input
                  id="hero_cardline5_data"
                  name="hero_cardline5_data"
                  value={hero_card.line5_data}
                  onChange={handleHeroChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-col w-1/2 flex-wrap -mx-3 ">
            <div className="mb-6 w-1/2 px-3">
              <ul>
                {formData.yt_videos.map((video, index) => (
                  <li key={index} className="flex items-center mb-2">
                    <span>{video}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveVideo(index)}
                    >
                      x
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-6 w-1/2 px-3">
              <FormControl fullWidth>
                <InputLabel htmlFor="newVideo">Add New Video</InputLabel>
                <Input
                  id="newVideo"
                  value={newVideo}
                  onChange={handleVideoChange}
                  variant="outlined"
                />
                <Button
                  className="w-full"
                  variant="contained"
                  color="black"
                  onClick={handleAddVideo}
                >
                  Add
                </Button>
              </FormControl>
            </div>
          </div>
          <br />
          <h2 className="text-2xl mb-4">Overview</h2>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="overviewheading">Heading</InputLabel>
                <Input
                  id="overviewheading"
                  name="overviewheading"
                  value={overview.heading}
                  onChange={handleOverviewChange}
                  multiline
                />
              </FormControl>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="overviewcontent">Content</InputLabel>
                <Input
                  id="overviewcontent"
                  name="overviewcontent"
                  value={overview.content}
                  onChange={handleOverviewChange}
                  multiline
                />
              </FormControl>
            </div>
          </div>
          <br />
          <h2 className="text-2xl mb-4">Gallery Slider Images</h2>

          <div className="flex flex-col flex-wrap -mx-3">
            <div className="mb-6 w-1/2 px-3">
              <ul>
                {formData.gallerySliderImages.map((video, index) => (
                  <li key={index} className="flex items-center mb-2">
                    <span>{video}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveGallery(index)}
                    >
                      x
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-6 w-1/2 px-3">
              <FormControl fullWidth>
                <InputLabel htmlFor="newGallery">Add New Gallery</InputLabel>
                <Input
                  id="newGallery"
                  value={newGallery}
                  onChange={handleGalleryChange}
                  variant="outlined"
                />
                <Button
                  className="w-full"
                  variant="contained"
                  color="black"
                  onClick={handleAddGallery}
                >
                  Add
                </Button>
              </FormControl>
            </div>
          </div>
          <br />
          <h2 className="text-2xl mb-4">Project Data</h2>

          <div className="flex flex-wrap -mx-3">
            <div style={{ width: "100%" }} className="mb-6 px-3">
              <div>
                {projectDetails.map((detail, index) => (
                  <div key={index}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor={`icon_url_${index}`}>
                        Icon URL
                      </InputLabel>
                      <Input
                        id={`icon_url_${index}`}
                        value={detail.icon_url}
                        onChange={(e) =>
                          handleDetailChange(index, "icon_url", e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel htmlFor={`heading_${index}`}>
                        Heading
                      </InputLabel>
                      <Input
                        id={`heading_${index}`}
                        value={detail.heading}
                        onChange={(e) =>
                          handleDetailChange(index, "heading", e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel htmlFor={`heading_value_${index}`}>
                        Heading Value
                      </InputLabel>
                      <Input
                        id={`heading_value_${index}`}
                        value={detail.heading_value}
                        onChange={(e) =>
                          handleDetailChange(
                            index,
                            "heading_value",
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: " 20px 0 20px 0",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleRemoveDetail(index)}
                      >
                        Remove
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddDetail}
                      >
                        Add Detail
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <h2 className="text-2xl mb-4">Units And Price</h2>

          <div className="flex flex-wrap -mx-3">
            <div style={{ width: "100%" }}>
              {unitAndPrice.map((unit, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`type_${index}`}>Type</InputLabel>
                    <Input
                      id={`type_${index}`}
                      value={unit.type}
                      onChange={(e) =>
                        handleChangeUnitAndPrice(index, "type", e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`subtype_${index}`}>
                      Subtype
                    </InputLabel>
                    <Input
                      id={`subtype_${index}`}
                      value={unit.subtype}
                      onChange={(e) =>
                        handleChangeUnitAndPrice(
                          index,
                          "subtype",
                          e.target.value
                        )
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`sbua_${index}`}>SBUA</InputLabel>
                    <Input
                      id={`sbua_${index}`}
                      value={unit.sbua}
                      onChange={(e) =>
                        handleChangeUnitAndPrice(index, "sbua", e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`price_${index}`}>Price</InputLabel>
                    <Input
                      id={`price_${index}`}
                      value={unit.price}
                      onChange={(e) =>
                        handleChangeUnitAndPrice(index, "price", e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`floor_plan_img_${index}`}>
                      Floor Plan Image
                    </InputLabel>
                    <Input
                      id={`floor_plan_img_${index}`}
                      value={unit.floor_plan_img}
                      onChange={(e) =>
                        handleChangeUnitAndPrice(
                          index,
                          "floor_plan_img",
                          e.target.value
                        )
                      }
                    />
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: " 20px 0 20px 0",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleRemoveUnitAndPrice(index)}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddUnitAndPrice}
                    >
                      Add Unit & Price
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h2 className="text-2xl mb-4">Floor Plans</h2>
          <div className="flex flex-wrap -mx-3">
            <div style={{ width: "100%" }}>
              {floorPlans.map((floorPlan, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`img_url_${index}`}>
                      Image URL
                    </InputLabel>
                    <Input
                      id={`img_url_${index}`}
                      value={floorPlan.img_url}
                      onChange={(e) =>
                        handleFloorPlanChange(index, "img_url", e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`title_${index}`}>Title</InputLabel>
                    <Input
                      id={`title_${index}`}
                      value={floorPlan.title}
                      onChange={(e) =>
                        handleFloorPlanChange(index, "title", e.target.value)
                      }
                    />
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: " 20px 0 20px 0",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleRemoveFloorPlan(index)}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddFloorPlan}
                    >
                      Add Floor Plan
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h2 className="text-2xl mb-4">Amenities</h2>
          <div className="flex flex-wrap -mx-3">
            <div style={{ width: "100%" }}>
              {amenities.map((amenity, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`title_${index}`}>Title</InputLabel>
                    <Input
                      id={`title_${index}`}
                      value={amenity.title}
                      onChange={(e) =>
                        handleAmenityChange(index, "title", e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`img_url_${index}`}>
                      Image URL
                    </InputLabel>
                    <Input
                      id={`img_url_${index}`}
                      value={amenity.img_url}
                      onChange={(e) =>
                        handleAmenityChange(index, "img_url", e.target.value)
                      }
                    />
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: " 20px 0 20px 0",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleRemoveAmenity(index)}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddAmenity}
                    >
                      Add Amenity
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h2 className="text-2xl mb-4">Master Plan Gallery</h2>
          <div className="flex flex-wrap -mx-3">
            <div style={{ width: "100%" }}>
              {masterPlanGallery.map((image, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`image_${index}`}>
                      Image URL
                    </InputLabel>
                    <Input
                      id={`image_${index}`}
                      value={image}
                      onChange={(e) =>
                        handleMasterPlanChange(index, e.target.value)
                      }
                    />
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: " 20px 0 20px 0",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleRemoveMasterPlan(index)}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddMasterPlan}
                    >
                      Add Image
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h2 className="text-2xl mb-4">Location Map Gallery</h2>
          <div className="flex flex-wrap -mx-3">
            <div style={{ width: "100%" }}>
              {locationMapGallery.map((image, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`image_${index}`}>
                      Image URL
                    </InputLabel>
                    <Input
                      id={`image_${index}`}
                      value={image}
                      onChange={(e) =>
                        handleLocationMapChange(index, e.target.value)
                      }
                    />
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: " 20px 0 20px 0",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleRemoveLocationMap(index)}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddLocationMap}
                    >
                      Add Image
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <br />
          <h2 className="text-2xl ">FAQs</h2>
          <div>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((faqIndex) => (
              <div key={faqIndex}>
                <div className="flex flex-wrap -mx-3">
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`faq${faqIndex}Q`}>
                      Question {faqIndex}
                    </InputLabel>
                    <Input
                      id={`faq${faqIndex}Q`}
                      value={faqs[`faq${faqIndex}`].q}
                      onChange={(e) =>
                        handleFaqChange(faqIndex, "q", e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`faq${faqIndex}A`}>Answer</InputLabel>
                    <Input
                      id={`faq${faqIndex}A`}
                      value={faqs[`faq${faqIndex}`].a}
                      onChange={(e) =>
                        handleFaqChange(faqIndex, "a", e.target.value)
                      }
                    />
                  </FormControl>
                </div>
              </div>
            ))}
          </div>
          <br />
          <h2 className="text-2xl mb-4">Location Section</h2>
          <div className="flex flex-wrap -mx-3">
            <FormControl fullWidth>
              <InputLabel htmlFor="mapLink">Map Link</InputLabel>
              <Input
                id="mapLink"
                value={locationSection.map_link}
                onChange={(e) =>
                  handleLocationSectionChange("map_link", e.target.value)
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="mapImageUrl">Map Image URL</InputLabel>
              <Input
                id="mapImageUrl"
                value={locationSection.map_img_url}
                onChange={(e) =>
                  handleLocationSectionChange("map_img_url", e.target.value)
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="content">Content</InputLabel>
              <Input
                id="content"
                value={locationSection.content}
                onChange={(e) =>
                  handleLocationSectionChange("content", e.target.value)
                }
              />
            </FormControl>
          </div>
          <h2 className="text-2xl mb-4">About Section</h2>
          <div className="flex flex-wrap -mx-3">
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="aboutHeading">About Heading</InputLabel>
                <Input
                  id="aboutHeading"
                  value={aboutSection.heading}
                  onChange={(e) =>
                    handleAboutSectionChange("heading", e.target.value)
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="builderLogoUrl">
                  Builder Logo URL
                </InputLabel>
                <Input
                  id="builderLogoUrl"
                  value={aboutSection.builder_logo_url}
                  onChange={(e) =>
                    handleAboutSectionChange("builder_logo_url", e.target.value)
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="aboutContent">About Content</InputLabel>
                <Input
                  id="aboutContent"
                  value={aboutSection.content}
                  onChange={(e) =>
                    handleAboutSectionChange("content", e.target.value)
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="aboutOngoing">Ongoing</InputLabel>
                <Input
                  id="aboutOngoing"
                  value={aboutSection.ongoing}
                  onChange={(e) =>
                    handleAboutSectionChange("ongoing", e.target.value)
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="aboutUpcoming">Upcoming</InputLabel>
                <Input
                  id="aboutUpcoming"
                  value={aboutSection.upcoming}
                  onChange={(e) =>
                    handleAboutSectionChange("upcoming", e.target.value)
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="aboutCompleted">Completed</InputLabel>
                <Input
                  id="aboutCompleted"
                  value={aboutSection.completed}
                  onChange={(e) =>
                    handleAboutSectionChange("completed", e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
          <div>
            <div>
              <br />
              <h2 className="text-2xl mb-4">Location Points</h2>

              <h2 className="text-xl mb-4">Hospital</h2>
              {locationPoints.hospital.map((point, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <Input
                      value={point}
                      onChange={(e) =>
                        handleLocationPointsChange(
                          "hospital",
                          index,
                          e.target.value
                        )
                      }
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        handleRemoveLocationPoint("hospital", index)
                      }
                    >
                      Remove
                    </Button>
                  </FormControl>
                </div>
              ))}
              <Button
                className="w-full"
                variant="outlined"
                color="primary"
                onClick={() => handleAddLocationPoint("hospital")}
              >
                Add Hospital
              </Button>
              <br />
              <br />

              <h2 className="text-xl mb-4">Schools</h2>
              {locationPoints.schools.map((point, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <Input
                      value={point}
                      onChange={(e) =>
                        handleLocationPointsChange(
                          "schools",
                          index,
                          e.target.value
                        )
                      }
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        handleRemoveLocationPoint("schools", index)
                      }
                    >
                      Remove
                    </Button>
                  </FormControl>
                </div>
              ))}
              <Button
                className="w-full"
                variant="outlined"
                color="primary"
                onClick={() => handleAddLocationPoint("schools")}
              >
                Add School
              </Button>
              <br />
              <br />

              <h2 className="text-xl mb-4">Landmarks</h2>
              {locationPoints.landmarks.map((point, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <Input
                      value={point}
                      onChange={(e) =>
                        handleLocationPointsChange(
                          "landmarks",
                          index,
                          e.target.value
                        )
                      }
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        handleRemoveLocationPoint("landmarks", index)
                      }
                    >
                      Remove
                    </Button>
                  </FormControl>
                </div>
              ))}
              <Button
                className="w-full"
                variant="outlined"
                color="primary"
                onClick={() => handleAddLocationPoint("landmarks")}
              >
                Add Landmark
              </Button>
              <br />
              <br />

              <h2 className="text-xl mb-4">Nearby</h2>
              {locationPoints.nearby.map((point, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <Input
                      value={point}
                      onChange={(e) =>
                        handleLocationPointsChange(
                          "nearby",
                          index,
                          e.target.value
                        )
                      }
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveLocationPoint("nearby", index)}
                    >
                      Remove
                    </Button>
                  </FormControl>
                </div>
              ))}
              <Button
                className="w-full"
                variant="outlined"
                color="primary"
                onClick={() => handleAddLocationPoint("nearby")}
              >
                Add Nearby Location
              </Button>
              <br />
              <br />
              <h2 className="text-xl mb-4">Others</h2>
              {locationPoints.others.map((point, index) => (
                <div key={index}>
                  <FormControl fullWidth>
                    <Input
                      value={point}
                      onChange={(e) =>
                        handleLocationPointsChange(
                          "others",
                          index,
                          e.target.value
                        )
                      }
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveLocationPoint("others", index)}
                    >
                      Remove
                    </Button>
                  </FormControl>
                </div>
              ))}
              <Button
                className="w-full"
                variant="outlined"
                color="primary"
                onClick={() => handleAddLocationPoint("others")}
              >
                Add Other Location
              </Button>
            </div>
          </div>
          <br />
          <h2 className="text-2xl mb-4">Add Colour Codes</h2>
          <div className="flex w-full -mx-3">
            <FormControl fullWidth>
              <InputLabel htmlFor="under_construction_color">
                Under Construction{" "}
              </InputLabel>
              <Input
                id="under_construction_color"
                value={headerBtnColorCodes.under_construction.color_code}
                onChange={(e) =>
                  handleColorCodeChange("under_construction", e.target.value)
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="new_launch_color">New Launch</InputLabel>
              <Input
                id="new_launch_color"
                value={headerBtnColorCodes.new_launch.color_code}
                onChange={(e) =>
                  handleColorCodeChange("new_launch", e.target.value)
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="ready_to_move_in_color">
                Ready to Move In{" "}
              </InputLabel>
              <Input
                id="ready_to_move_in_color"
                value={headerBtnColorCodes.ready_to_move_in.color_code}
                onChange={(e) =>
                  handleColorCodeChange("ready_to_move_in", e.target.value)
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="possession_color">Possession</InputLabel>
              <Input
                id="possession_color"
                value={headerBtnColorCodes.possession.color_code}
                onChange={(e) =>
                  handleColorCodeChange("possession", e.target.value)
                }
              />
            </FormControl>
          </div>
          <br />

          <h2 className="text-2xl mb-4">Specifications</h2>
          <FormControl fullWidth>
            <InputLabel htmlFor="specificationHeading">Heading</InputLabel>
            <Input
              id="specificationHeading"
              value={specifications.heading}
              onChange={(e) =>
                setSpecifications({
                  ...specifications,
                  heading: e.target.value,
                })
              }
            />
          </FormControl>
          <div className="flex flex-wrap -mx-3">
            <div>
              {" "}
              <FormControl fullWidth>
                <InputLabel htmlFor="structureHeading">
                  Structure Heading
                </InputLabel>
                <Input
                  id="structureHeading"
                  value={structure.heading}
                  onChange={handleStructureHeadingChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="structureImgUrl">
                  Structure Image URL
                </InputLabel>
                <Input
                  id="structureImgUrl"
                  value={structure.img_url}
                  onChange={handleStructureImgUrlChange}
                />
              </FormControl>
              {structure.content.map((content, index) => (
                <FormControl fullWidth key={index}>
                  <InputLabel htmlFor={`structureContent${index}`}>
                    Structure Content {index + 1}
                  </InputLabel>
                  <Input
                    id={`structureContent${index}`}
                    value={content}
                    onChange={(e) => handleStructureContentChange(e, index)}
                  />
                </FormControl>
              ))}
              <Button
                onClick={addStructureContentField}
                variant="outlined"
                color="primary"
              >
                Add Structure Content
              </Button>
            </div>{" "}
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="flooringHeading">
                  Flooring Heading
                </InputLabel>
                <Input
                  id="flooringHeading"
                  value={flooring.heading}
                  onChange={handleFlooringHeadingChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="flooringImgUrl">
                  Flooring Image URL
                </InputLabel>
                <Input
                  id="flooringImgUrl"
                  value={flooring.img_url}
                  onChange={handleFlooringImgUrlChange}
                />
              </FormControl>
              {flooring.content.map((content, index) => (
                <FormControl fullWidth key={index}>
                  <InputLabel htmlFor={`flooringContent${index}`}>
                    Flooring Content {index + 1}
                  </InputLabel>
                  <Input
                    id={`flooringContent${index}`}
                    value={content}
                    onChange={(e) => handleFlooringContentChange(e, index)}
                  />
                </FormControl>
              ))}
              <Button
                onClick={addFlooringContentField}
                variant="outlined"
                color="primary"
              >
                Add Flooring Content
              </Button>
            </div>
          </div>
          <br />
          <div className="flex flex-wrap -mx-3">
            {" "}
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="electricalHeading">
                  Electrical Heading
                </InputLabel>
                <Input
                  id="electricalHeading"
                  value={electrical.heading}
                  onChange={handleElectricalHeadingChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="electricalImgUrl">
                  Flooring Image URL
                </InputLabel>
                <Input
                  id="electricalImgUrl"
                  value={electrical.img_url}
                  onChange={handleElectricalImgUrlChange}
                />
              </FormControl>
              {electrical.content.map((content, index) => (
                <FormControl fullWidth key={index}>
                  <InputLabel htmlFor={`electricalContent${index}`}>
                    Electrical Content {index + 1}
                  </InputLabel>
                  <Input
                    id={`electricalContent${index}`}
                    value={content}
                    onChange={(e) => handleElectricalContentChange(e, index)}
                  />
                </FormControl>
              ))}
              <Button
                onClick={addElectricalContentField}
                variant="outlined"
                color="primary"
              >
                Add Electrical Content
              </Button>
            </div>{" "}
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="doorsHeading">Doors Heading</InputLabel>
                <Input
                  id="doorsHeading"
                  value={doors.heading}
                  onChange={handleDoorsHeadingChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="doorsImgUrl">Doors Image URL</InputLabel>
                <Input
                  id="doorsImgUrl"
                  value={doors.img_url}
                  onChange={handleDoorsImgUrlChange}
                />
              </FormControl>
              {doors.content.map((content, index) => (
                <FormControl fullWidth key={index}>
                  <InputLabel htmlFor={`doorsContent${index}`}>
                    Doors Content {index + 1}
                  </InputLabel>
                  <Input
                    id={`doorsContent${index}`}
                    value={content}
                    onChange={(e) => handleDoorsContentChange(e, index)}
                  />
                </FormControl>
              ))}
              <Button
                onClick={addDoorsContentField}
                variant="outlined"
                color="primary"
              >
                Add Doors Content
              </Button>
            </div>
          </div>
          <br />

          <div className="flex flex-wrap -mx-3">
            {" "}
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="plumbing_and_sanitaryHeading">
                  Plumbing and Sanitary Heading
                </InputLabel>
                <Input
                  id="plumbing_and_sanitaryHeading"
                  value={plumbing_and_sanitary.heading}
                  onChange={handlePlumbing_and_sanitaryHeadingChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="plumbing_and_sanitaryImgUrl">
                  Plumbing And Sanitary Image URL
                </InputLabel>
                <Input
                  id="plumbing_and_sanitaryImgUrl"
                  value={plumbing_and_sanitary.img_url}
                  onChange={handlePlumbing_and_sanitaryImgUrlChange}
                />
              </FormControl>
              {plumbing_and_sanitary.content.map((content, index) => (
                <FormControl fullWidth key={index}>
                  <InputLabel htmlFor={`plumbing_and_sanitaryContent${index}`}>
                    Plumbing And Sanitary Content {index + 1}
                  </InputLabel>
                  <Input
                    id={`plumbing_and_sanitaryContent${index}`}
                    value={content}
                    onChange={(e) =>
                      handlePlumbing_and_sanitaryContentChange(e, index)
                    }
                  />
                </FormControl>
              ))}
              <Button
                onClick={addPlumbing_and_sanitaryContentField}
                variant="outlined"
                color="primary"
              >
                Add Plumbing And Sanitary Content
              </Button>
            </div>{" "}
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="securityHeading">
                  Security Heading
                </InputLabel>
                <Input
                  id="securityHeading"
                  value={security.heading}
                  onChange={handleSecurityHeadingChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="securityImgUrl">
                  Security Image URL
                </InputLabel>
                <Input
                  id="securityImgUrl"
                  value={security.img_url}
                  onChange={handleSecurityImgUrlChange}
                />
              </FormControl>
              {security.content.map((content, index) => (
                <FormControl fullWidth key={index}>
                  <InputLabel htmlFor={`securityContent${index}`}>
                    Security Content {index + 1}
                  </InputLabel>
                  <Input
                    id={`securityContent${index}`}
                    value={content}
                    onChange={(e) => handleSecurityContentChange(e, index)}
                  />
                </FormControl>
              ))}
              <Button
                onClick={addSecurityContentField}
                variant="outlined"
                color="primary"
              >
                Add Security Content
              </Button>
            </div>
          </div>
          <br />

          <Button variant="contained" color="primary" type="submit">
            Update
          </Button>
        </form>
      </div>
    </div>
  );
}

export default EditForm;
