import { useState } from "react";
import {
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  Flex,
  Box,
  Icon,
} from "@chakra-ui/react";
import { HamburgerIcon, StarIcon, EmailIcon, AddIcon } from "@chakra-ui/icons";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CategoryIcon from "@mui/icons-material/Category";
import { makeStyles } from "@material-ui/core/styles";
import { adminlogout } from "../redux/reducers/adminslice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { NavLink, useLocation } from "react-router-dom";
function SideHamburgerMenu() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeLinkStyle = {
    backgroundColor: "black",
    color: "white",
    // Add any other styles you want for active links
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const iconStyle = {
    fontSize: "26px",
    marginRight: "12px",
  };

  const Menustyle = {
    padding: 8,
    margin: 9,
    marginLeft: -6,
  };
  const [clicked, setClicked] = useState(false);
  return (
    <div>
      <div className=" h-screen float-left">
        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          onClick={toggleMenu}
        />
      </div>
      <Drawer isOpen={isOpen} placement="left" onClose={toggleMenu}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <HamburgerIcon style={{ fontSize: "28px", marginRight: "3px" }} />{" "}
            <span className="font-extrabold">Propmile Admin</span>
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              {isOpen ? (
                <Box>
                  <NavLink to="/admin/">
                    <div
                      className="hover:bg-black hover:text-white  hover:font-bold hover:transition-all  duration-300 m-2 m-2 p-2 rounded-md -ml-1"
                      style={
                        location.pathname === "/admin"
                          ? activeLinkStyle
                          : null
                      }
                    >
                      <CorporateFareIcon style={iconStyle} />
                      Properties
                    </div>
                  </NavLink>
                  <NavLink to="/admin/enquiries">
                    <div
                      className="hover:bg-black hover:text-white  hover:font-bold hover:transition-all  duration-300 m-2 p-2 rounded-md -ml-1"
                      style={
                        location.pathname === "/admin/enquiries"
                          ? activeLinkStyle
                          : null
                      }
                    >
                      <AssignmentIcon style={iconStyle} />
                      Enquiries
                    </div>
                  </NavLink>
                  <NavLink to="/admin/comments">
                    <div
                      className="hover:bg-black hover:text-white  hover:font-bold hover:transition-all  duration-300 m-2 p-2 rounded-md -ml-1"
                      style={
                        location.pathname === "/admin/comments"
                          ? activeLinkStyle
                          : null
                      }
                    >
                      <RateReviewIcon style={iconStyle} />
                      Reviews
                    </div>
                  </NavLink>
                  <NavLink to="/admin/users">
                    <div
                      className="hover:bg-black hover:text-white  hover:font-bold hover:transition-all  duration-300 m-2 p-2 rounded-md -ml-1"
                      style={
                        location.pathname === "/admin/users"
                          ? activeLinkStyle
                          : null
                      }
                    >
                      <PeopleAltIcon style={iconStyle} />
                      Clients
                    </div>
                  </NavLink>
                  <NavLink to="/admin/register">
                    <div
                      className="hover:bg-black hover:text-white  hover:font-bold hover:transition-all  duration-300 m-2 p-2 rounded-md -ml-1"
                      style={
                        location.pathname === "/admin/register"
                          ? activeLinkStyle
                          : null
                      }
                    >
                      <PeopleAltIcon style={iconStyle} />
                      Register
                    </div>
                  </NavLink>
                  <NavLink to="/admin/login">
                    <div
                      className="hover:bg-black hover:text-white  hover:font-bold hover:transition-all  duration-300 m-2 p-2 rounded-md -ml-1"
                      style={
                        location.pathname === "/admin/login"
                          ? activeLinkStyle
                          : null
                      } onClick={()=>{console.log("Hello");dispatch(adminlogout({navigate}))}}
                    >
                      <PeopleAltIcon style={iconStyle} />
                      Logout
                    </div>
                  </NavLink>
                  
                </Box>
              ) : (
                <Box as="a" href="#">
                  <div>Menu Item 4</div>
                </Box>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default SideHamburgerMenu;
