import { Box, Flex, GridItem, Image,Heading,Spinner, SimpleGrid } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import CardsSection from '../components/Home/CardsSection';
import HeroSection from '../components/Home/HeroSection';
import NewLaunchedProjects from '../components/Home/NewLaunchedProjects';
import NewLaunchSlider from '../components/Home/NewLaunchSlider';
import ChatBot from '../components/SingleProperty/ChatBot';
import CollapsibleNavbar from '../components/CollapseNav';
import Categories from '../components/Home/Categories';
import foot1 from '../components/Assets/foot1.png'
import foot2 from '../components/Assets/foot2.png'
import foot3 from '../components/Assets/foot3.png'
import foot4 from '../components/Assets/foot4.png'
import foot5 from '../components/Assets/foot5.png'
import foot6 from '../components/Assets/foot6.png'
import foot7 from '../components/Assets/foot7.png'
import foot8 from '../components/Assets/foot8.png'
import foot9 from '../components/Assets/foot9.png'
import foot10 from '../components/Assets/foot10.png'
import foot11 from '../components/Assets/foot11.png'
import HelpSection from '../components/Home/HelpSection';

function Homepage(props) {
    const { data, ad } = props;
    const [ddata, setData] = useState({});
    const [flag, setFlag] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
          try {
            const res = await axios.get(
              `https://real-estate-backend-lg1o.onrender.com/api/v1/properties`
            );
            console.log(res.data);
            setData(res.data);
            setLoading(false);
          } catch (err) {
            console.log(err);
            setFlag(false);
            setLoading(false);
          }
        };
        getData();
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        // eslint-disable-next-line
      }, []);
    return (
        <>
              <Header data={data} />
        {loading ? (
            <Box
              h="90vh"
              maxWidth={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
              />{" "}
              Loading
            </Box>
          ) : (<>
            <HeroSection />
            <ChatBot/>
            <Categories />
            <Box display={['block']} w='100%'>
                {data && <NewLaunchSlider data={data} />}
            </Box>
            <Box display={['none', 'none', 'block', 'block']}>
                {ad && <NewLaunchedProjects ad1={ad[0]} ad2={ad[1]} />}
            </Box>
            <CardsSection data={props.data} />
            <Box display={['block', 'block', 'none', 'none']}>
                {ad && <NewLaunchedProjects ad1={ad[0]} ad2={ad[1]} />}
            </Box>
            <HelpSection /> 
            <Box display={['block', 'block', 'block', 'block', 'block']}>
                <Flex padding={4} background={'#92B161'} justifyContent={'center'}>
                    <Heading fontSize={'1.5rem'} color={'white'}>In the spotlight: Magazines that have featured us.</Heading>
                </Flex>
                <Flex spacing='8' background={'#92B161'} justifyContent={'center'}>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3} marginLeft={0} >
                        <Image minH={['none','none','50px']} w='120px' 
                            objectFit='contain' src={foot1} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot2} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot3} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot4} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot5} alt='' />
                    </Box>
                </Flex>
                <Flex spacing='8' paddingBottom={4} background={'#92B161'} justifyContent={'center'}>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3} marginTop={0} marginLeft={0} >
                        <Image minH={['none','none','50px']} w='120px' 
                            objectFit='contain' src={foot6} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3}  marginTop={0} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot7} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3}  marginTop={0} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot8} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3}  marginTop={0} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot9} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3}  marginTop={0} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot10} alt='' />
                    </Box>
                    <Box background={'white'} display={'flex'} justifyContent={'center'} width={'13%'} borderRadius={'5px'} margin={3}  marginTop={0} marginLeft={0} >
                        <Image w='120px' 
                            objectFit='contain' src={foot11} alt='' />
                    </Box>
                    
                </Flex>
            </Box></>) }
        </>
    );
}

export default Homepage;