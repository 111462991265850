import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AdminHeader from "./AdminHeader";
import {baseURL} from "./data";

const Users = () => {
  const theme = createTheme({
    mode: "dark", // Set to "dark" for dark mode, if needed
    // Other theme properties...
  });
  const [users, setUsers] = useState([]);
  const SetAllUsers = async () => {
    const res = await fetch(`${baseURL}admin/getAllUsers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const jack = await res.json();
    var hmm = 0;
    setUsers(
      jack.map((property) => {
        hmm += 1;
        return { ...property, id: hmm };
      })
    );
    console.log(jack, jack.comments, "USERINFO");
  };
  const columns = [
    { field: "id", headerName: "ID", width: 33 },
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Phone",
      headerName: "Phone Number",
      headerAlign: "left",
      align: "left",
      flex: 3,
      width: "10%",
    },
    {
      field: "createdAt",
      headerName: "Register Date",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 3,
      width: "10%",
    },
  ];
  SetAllUsers();
  return (
    <Box>
      <AdminHeader />
      <div
        className="text-left"
        style={{
          fontSize: 30,
          width: "80%",
          margin: "auto",
          marginTop: 30,
          marginBottom: 10,
          fontWeight: 500,
          backgroundColor: "#f2f2f2",
          borderRadius: 5,
          padding: 10,
        }}
      >
        Users
      </div>

      <Box sx={{ height: "800px", width: "80%", mx: "auto" }}>
        <ThemeProvider theme={theme}>
          <DataGrid
            slots={{
              toolbar: GridToolbar,
            }}
            rows={users}
            // @ts-ignore
            columns={columns}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default Users;
