import { Input, color } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminlogin, registerAdmin } from "../../redux/reducers/adminslice";
import { useDispatch } from "react-redux";
import AdminHeader from "./AdminHeader";

const AdminRegister = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [Username, setUsername] = useState('')
    const [Password, setPassword] = useState('')
    const [data,setdata] = useState({
        Username:"",
        Password:""
      })
    const handleChange = (e) => {
      setdata({ ...data, [e.target.name]: e.target.value });
    };
    console.log(Username, Password);
    const loginButton =(e)=>{
        e.preventDefault();
        dispatch(registerAdmin({data,navigate}))
    }
  return (
    <div>
      <AdminHeader />
      <div
        style={{ padding: 10, fontSize: 20, marginTop: '2rem', marginBottom: 1 }}
        className="text-center w-full shadow-md"
      >
       Add New Admin 
      </div>

        <div style={{width:'30%', minWidth:'200px', paddingTop: '3rem', paddingBottom: '4rem',display: 'flex', margin: 'auto', marginTop:'2%',marginBottom:'10%', background:'rgb(90, 90, 90)',borderRadius:".5rem", color:'white', padding:'1.5rem',flexDirection: 'column', justifyContent: 'center'}}>
            <div>
            <div style={{padding: '.5rem'}}>Username</div>
            <Input style={{background: 'white', marginBottom: '1rem', color:'black'}} value={data.Username} onChange={handleChange} name="Username" />
        </div>
        <div>
            <div style={{padding: '.5rem'}}>Password</div>
            <Input style={{background: 'white', marginBottom: '2.5rem', color:'black'}} value={data.Password} onChange={handleChange} name="Password" type="password" />
        </div>
        <button style={{color:"black", background: "white", padding: ".3rem", borderRadius:".2rem"}} onClick={loginButton}>Register</button>
        </div>
    </div>
  );
};

export default AdminRegister;
