import React from 'react';
import { Box, Flex, Text,VStack, Heading, Button, HStack, Image } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
// import jsonData from '../../constants/data.json';
import help from '../Assets/help.png'
import poster from '../Assets/poster.jpg'

function HelpSection() {
  const navigate = useNavigate();
  return (
    <Box >
      <Flex color={"white"} background={"#92B161"} flexDir={['column', 'column', 'row']} justifyContent='space-around' gap=''>
      <VStack m={'auto'} w={["90%","90%","50%"]} padding={4}>
      <Text>
      <Heading textAlign={'left'} fontWeight={"700"} marginBottom={"1%"} fontSize={["31px"]}>
          Numbers That Might Interest You
      </Heading>  
      "Explore a diverse range of real estate options on our property website, featuring apartments, plots, villas, and townships. Find your dream home with our extensive listings, offering a variety of choices to suit every lifestyle and preference.
      </Text>
      </VStack>
      <HStack w={"50%"} gap={4} justifyContent={'center'} m = {2}>
        <VStack>
        <Text textAlign={'center'} fontWeight={"600"} fontSize={"31px"}>
        2400+
        </Text>  
      <Text textAlign={'center'}>
        Customers 
        </Text>  
        </VStack>
        <VStack>
        <Text textAlign={'center'} fontWeight={"600"} fontSize={"31px"}>
        100%
        </Text>  
      <Text textAlign={'center'}>
        Customer Satisfaction
        </Text>  
        </VStack>
        <VStack>
        <Text textAlign={'center'} fontWeight={"600"} fontSize={"31px"}>
        3M+
        </Text>  
      <Text textAlign={'center'}>
        Annual Sales 
        </Text>  
        </VStack>
        <VStack>
        <Text textAlign={'center'} fontWeight={"600"} fontSize={"31px"}>
        4.8
        </Text>  
      <Text textAlign={'center'}>
        Customer Rating 
        </Text>  
        </VStack>
      </HStack>
      </Flex>
      <Flex direction={['column', 'column', 'row']} m={"2%"} justifyContent={['center','center','space-around']}>
      <Image objectFit='contain' w={["100%","100%","49%"]} src={help} />
      <Image objectFit='contain' w={["100%","100%","49%"]} src={poster} />
      </Flex>  
    </Box>
  );
}

export default HelpSection;