import React, { useState } from 'react';
import { useEffect } from 'react';
import Header from '../components/Header';
import ListBody from '../components/List/ListBody';
import Categories from '../components/Home/Categories';
import PropertyCard from '../components/Cards/PropertyCard';
import { Button,SimpleGrid,Menu,MenuButton,MenuList,MenuOptionGroup,MenuItemOption,MenuDivider,Text, VStack, Box, Flex } from '@chakra-ui/react';
import apartments from '../components/Assets/apartments.png'
import villas from '../components/Assets/villas.png'
import plots from '../components/Assets/plots.png'
import townships from '../components/Assets/townships.png'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function CategoryListpage(props) {
  let { data } = props;
  console.log(data);
  const [properties, setproperties] = useState([])
  const [heading, setHeading] = useState('')
  const [type, settype] = useState('')
  const [roomOpt, setroomOpt] = useState('')
  const [status, setstatus] = useState('')
  const [location, setlocation] = useState('')

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  })
  useEffect(()=>{
    setproperties(data.data.properties)
    setproperties(data.data.properties?.filter((p) =>{ return location == '' ? true : location == p.category})?.filter((p) =>{ return status == '' ? true : status == p.subcategory}).filter((p)=>{return type == '' ? true : type == p.propertyCategory}).filter((p)=>{return roomOpt == '' ? true : p.floor_plans.find((item)=> {return item.title === roomOpt})}))
    console.log(properties);
  }, [data, location, status, type, roomOpt])
  
  return (
    <>
      <Header data={data} />
<>
<Box color={"#92B161"} display={['none', 'flex', 'flex']}>
      <Flex flexDir={['column', 'row', 'row']} justifyContent='space-around' wrap={'nowrap'} gap=''>
        <VStack style={{display:"flex", justifyContent:"center", alignItems:"center"}} padding="3">
        <ChevronLeftIcon style={{color:"rgb(38 41 33)",borderRadius:"50%", background:"rgb(200 216 176)"}} />
        </VStack>
        
        <VStack p='4' onClick={()=>{settype('apartments');setHeading('Apartments')}} key={1}>
          <img width="100%" src={apartments} alt="" />
          <Text fontWeight={'600'} fontSize='xl'>Apartments</Text>
        </VStack>
        <VStack p='4' onClick={()=>{settype('villas');setHeading('Villas')}} key={2}>
          <img width="100%" src={villas} alt="" />
          <Text fontWeight={'600'} fontSize='xl'>Villas</Text>
        </VStack>
        <VStack p='4' onClick={()=>{settype('plots');setHeading('Plots')}} key={3}>
          <img width="100%" src={plots} alt="" />
          <Text fontWeight={'600'} fontSize='xl'>Plots</Text>
        </VStack>
        <VStack p='4' onClick={()=>{settype('townships');setHeading('Townships')}} key={4}>
          <img width="100%" src={townships} alt="" />
          <Text fontWeight={'600'} fontSize='xl'>Townships</Text>
        </VStack>
        <VStack style={{display:"flex", justifyContent:"center", alignItems:"center"}} padding="3">
        <ChevronRightIcon style={{color:"rgb(38 41 33)",borderRadius:"50%", background:"rgb(200 216 176)"}} />
        </VStack>
        </Flex>
    </Box>
</>
<Menu closeOnSelect={false} >
  <MenuButton position={['',"absolute","absolute"]} right={['','0','0']} style={{margin:"2rem", float:'right'}} as={Button} colorScheme='green'>
    Filter <KeyboardArrowDownIcon />
  </MenuButton>
  <MenuList display={'flex'} p={2} flexDirection={'column'}> 
<Menu closeOnSelect={false} >
  <MenuButton style={{}} as={Button} >
    Type <KeyboardArrowDownIcon />
  </MenuButton>
  <MenuList>
    <MenuOptionGroup defaultValue='asc' title='Type' type='radio'>
      <MenuItemOption onClick={()=>{settype('apartments')}} value='apartments'>Apartments</MenuItemOption>
      <MenuItemOption onClick={()=>{settype('villas')}} value='villas'>Villas</MenuItemOption>
      <MenuItemOption onClick={()=>{settype('plots')}} value='plots'>Plots</MenuItemOption>
      <MenuItemOption onClick={()=>{settype('townships')}} value='townships'>Townships</MenuItemOption>
      <MenuItemOption onClick={()=>{settype('')}} value='all'>All</MenuItemOption>
    </MenuOptionGroup>
  </MenuList>
</Menu>
<Menu closeOnSelect={false} >
  <MenuButton style={{marginTop:".5rem"}} as={Button} >
  Room Options <KeyboardArrowDownIcon />
  </MenuButton>
  <MenuList>
    <MenuOptionGroup defaultValue='asc' title='Type' type='radio'>
      <MenuItemOption onClick={()=>{setroomOpt('1 BHK')}} value='1 BHK'>1 BHK</MenuItemOption>
      <MenuItemOption onClick={()=>{setroomOpt('2 BHK')}} value='2 BHK'>2 BHK</MenuItemOption>
      <MenuItemOption onClick={()=>{setroomOpt('3 BHK')}} value='3 BHK'>3 BHK</MenuItemOption>
      <MenuItemOption onClick={()=>{setroomOpt('4 BHK')}} value='4 BHK'>4 BHK</MenuItemOption>
      <MenuItemOption onClick={()=>{setroomOpt('')}} value='all'>All</MenuItemOption>
    </MenuOptionGroup>
  </MenuList>
</Menu>
<Menu closeOnSelect={false} >
  <MenuButton style={{marginTop:".5rem"}} as={Button} >
    Status <KeyboardArrowDownIcon />
  </MenuButton>
  <MenuList>
    <MenuOptionGroup defaultValue='asc' title='Status' type='radio'>
      <MenuItemOption onClick={()=>{setstatus('upcoming');console.log(status)}} value='upcoming'>Upcoming</MenuItemOption>
      <MenuItemOption onClick={()=>{setstatus('new launch');console.log(status)}} value='new launch'>New Launch</MenuItemOption>
      <MenuItemOption onClick={()=>{setstatus('under construction');console.log(status)}} value='under construction'>Under Construction</MenuItemOption>
      <MenuItemOption onClick={()=>{setstatus('ready to move in');console.log(status)}} value='ready to move in'>Ready To Move In</MenuItemOption>
      <MenuItemOption onClick={()=>{setstatus('');console.log(status)}} value='all'>All</MenuItemOption>
    </MenuOptionGroup>
  </MenuList>
</Menu>
<Menu closeOnSelect={false} >
  <MenuButton style={{marginTop:".5rem"}} as={Button} >
    Location <KeyboardArrowDownIcon />
  </MenuButton>
  <MenuList>
    <MenuOptionGroup defaultValue='asc' title='Location' type='radio'>
      <MenuItemOption onClick={(e)=>{setlocation('bangalore east');console.log(location)}} value='bangalore east'>Bangalore East</MenuItemOption>
      <MenuItemOption onClick={(e)=>{setlocation('bangalore west');console.log(location)}} value='bangalore west'>Bangalore West </MenuItemOption>
      <MenuItemOption onClick={(e)=>{setlocation('bangalore north');console.log(location)}} value='bangalore north'>Bangalore North </MenuItemOption>
      <MenuItemOption onClick={(e)=>{setlocation('bangalore south');console.log(location)}} value='bangalore south'>Bangalore South</MenuItemOption>
      <MenuItemOption onClick={(e)=>{setlocation('');console.log(location)}} value='all'>All</MenuItemOption>
    </MenuOptionGroup>
  </MenuList>
</Menu>

  </MenuList>
</Menu>


  <Text margin={'auto'} style={{color:"#92B161"}} textAlign={'center'} display={'flex'} fontWeight={"600"} marginTop={"2rem"} marginBottom={"1.5rem"} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} fontSize={["21px","28px","28px"]}>
    {heading}
    {!heading && <div>All Properties <hr width="100%" style={{border:"2px solid #92B161"}}/></div>}
    {heading && <hr width="6%" style={{border:"2px solid #92B161"}}/>}
  </Text>
  <br />
  <Flex padding={'1rem'}  justifyContent={'center'} flexWrap={'wrap'} spacingX='20px' spacingY='10px'>
  {properties?.map((d, i)=>{
    return <PropertyCard key={i} data = {d} />
  })}
  </Flex>
     
    </>
  );
}

export default CategoryListpage;